import axios, { AxiosError } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import './App.css';

interface API {
  url: string;
  name: string;
  error?: boolean;
  httpStatus?: number;
}

const APIs = [
  {
    name: 'RubyAPI',
    url: 'https://www.iamyiam.com/api/status/',
  },
  {
    name: 'NodeAPI',
    url: 'https://www.iamyiam.com/api/v2/countries',
  },
  {
    name: 'ML API',
    url: 'https://ml.iamyiam.com/',
  },
  {
    name: 'ChatAPI',
    url: 'https://chat-agent.syd.life/',
  },
  {
    name: 'RecommenderAPI',
    url: 'https://recommender.syd.life/',
  },
];

function App() {
  const [apis, setApis] = useState<API[]>(APIs);

  const checkAPIStatus = useCallback(async (api: API): Promise<API> => {
    let error: boolean | undefined = undefined;
    let httpStatus: number | undefined = undefined;

    try {
      await axios.get(api.url);
      error = false;
    } catch (e) {
      error = true;
      httpStatus = (e as AxiosError).response?.status;
    }

    return {
      ...api,
      error,
      httpStatus,
    };
  }, []);

  useEffect(() => {
    for (const api of APIs) {
      checkAPIStatus(api).then((result) => {
        setApis((oldAPIs) => {
          const index = oldAPIs.findIndex((item) => item.name === result.name);
          if (index >= 0) {
            oldAPIs[index] = result;
          }

          return [...oldAPIs];
        });
      });
    }
  }, [checkAPIStatus]);

  return (
    <div className="container">
      {apis.map((item) => (
        <APICell api={item} key={item.name} />
      ))}
    </div>
  );
}

const APICell = ({ api }: { api: API }) => {
  const { name, error, httpStatus } = api;

  const statusText = useMemo(() => {
    if (error === undefined) {
      return 'Checking...';
    } else if (error === true) {
      return `${httpStatus ?? ''} Error`;
    } else {
      return 'OK';
    }
  }, [error, httpStatus]);

  const colorCSS = useMemo(() => {
    if (error === true) {
      return 'red';
    } else if (error === false) {
      return 'green';
    } else {
      return 'pink';
    }
  }, [error]);

  return (
    <div className="apiCellContainer">
      <span className="nameLabel">{name}</span>
      <span className={`statusContainer ${colorCSS}`}>{statusText}</span>
    </div>
  );
};

export default App;
